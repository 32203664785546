@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body{
    background: #333;
    color: #fff;
	margin:0;
	padding:0;
	min-height: 100%;
	font-size: 10px;
	font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html{
    min-height: 100%;
    overflow-x: hidden; 
    overflow-y: auto;
    overflow: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
button,
svg {
  display: inline-block;
  vertical-align: middle;
}
a{
	text-decoration: none;
}
h1,h2,h3,h4,h5,h6,p{
	font-size: 12px;
	font-weight: normal;
	margin: 0;
	padding: 0;
	color: #cecece;
}
ul{
	list-style: none;
	margin: 0;
	padding: 0;
}
button:active, button {
    outline: none!important;
    border: none;
}
::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
::-webkit-input-placeholder{
	color: #151515;
	opacity: 1;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
::placeholder{
	color: #151515;
	opacity: 1;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
:-ms-input-placeholder{
	color: #151515;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
::-ms-input-placeholder{
	color: #151515;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
.App {
  text-align: center;
}
.container{
	background-color: #333;
}
button {
    color: inherit;
    text-transform: none;
    text-indent: inherit;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: inherit;
    margin: 0em;
    font: inherit;
    padding: 0;
    border-width: 0;
    border-style: none;
    border-color: inherit;
    border-image: none;
}
/* HEADER MOBILE */

.header_devices{
	background-color: #180000;
    display: flex;
	align-items: center;
	height: 60px;
    padding: 5px;
    position: fixed;
    bottom: 0;
	width: calc(100% - 10px);
	z-index: 1000;
	box-shadow: 0px -10px 30px 0px rgba(0,0,0,0.5);
}
.logo a{
	display: flex;
}
.logo img{
	height: 50px;
	padding: 5px;
}
.toolbar{
	display: flex;
	align-items: center;
    flex: 1 1;
    height: 60px;
	justify-content: flex-end;
}
.toolbar h1{
	font-size: 10px;
	margin-top: 4px;
}
.header_icon{
	height: 30px;
	width: 30px;
}
.open_filters{
	padding: 5px;
}
.menu_openers{
	display: flex;
}
.open_search{
	padding: 5px;
}
.open_menu{
	padding: 5px;
}

/* MENU */

.menu_container{
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: flex;
	z-index: 99999;
}
.overlay{
	flex: 1 1;
	background-color: rgba(0, 0, 0, 0.8);
}
#menu{
	width: 250px;
	height: 100%;
	background-color: #180000;
	box-sizing: border-box;
}
.menu_top{
	display: flex;
	padding: 5px;
}
.free_account_btn{
	flex: 1 1;
}
.free_account_btn a{
    color: #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background: rgb(176,16,0);
    background: linear-gradient(0deg, rgb(144, 13, 0) 0%, rgba(191,17,0,1) 100%);
	font-size: 13px;
	border-radius: 3px;
}
.close_menu_btn{
    display: flex;
    align-items: center;
    justify-content: center;
	margin-left: 5px;
}
.close_menu_icon{
	height: 25px;
    width: 25px;
	padding: 10px;
}
.menu_middle h1{
    font-size: 10px;
    padding: 10px 5px;
    text-align: left;
    background-color: #180000;
    margin: 0 5px;
}
/* menu search */
.search_wrap{
	padding: 5px;
}
#search{
	display: flex;
}
.search_input{
    padding: 12px;
    width: 75%;
    border: none;
    outline: none;
    background-color: #fff;
	color: #151515;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
}
#search_button{
    width: auto;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
	background-color: #fff;
	width: 50px;
}
.search_menu_icon{
    width: 20px;
    height: 20px;
}
#search_suggestions{
	position: absolute;
	z-index: 50;
}
#search_suggestions li a{
	font-size: 12px;
    padding: 12px;
    background-color: #fff;
	text-align: left;
	display: block;
	color: #151515;
}

/* Menu categories */

.menu_list{
    display: flex;
    flex-direction: column;
	height: calc(100% - 172px);
    color: #cecece;
    margin: 5px;
	overflow-y: scroll;
	background-color: #100000;
}
.menu_list li{
	font-size: 10px;
	text-align: left;
	color: #ececec;
}
.menu_list li a{
	color: #a5a5a5;
	font-size: 10px;
	padding: 12px 20px;
	text-align: left;
	display: block;
	background-color: #100000;
}
.category_heading{
	padding: 12px;
	background-color: #180000;
	border-left: 1px solid #4e0700;
}
.active_menulink{
    color: #ececec !important;
}

/* FILTERBOX MOBILE */

.filters_devices{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
	background-color: #180000;
	padding: 5px;
	box-sizing: border-box;
	display: flex;
    flex-direction: column;
}
.filters_title_close{
	display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2px 5px 2px;
    flex: 1 1;
}
.close_filters{
	display: flex;
}
.close_icon{
	width: 25px;
	height: 25px;
}
.filters_devices h2{
	font-size: 10px;
}
.sorters{
    display: flex;
    align-items: center;
    flex: 1 1;
}
.sorter{
    flex: 1 1;
    font-size: 10px;
    border: none;
    outline: none;
    height: 25px;
    margin: 0 2px;
	background-color: #2d0000;
    color: #cecece;
	font-family: 'Roboto', sans-serif;
	border-radius: 3px;
}
.sorter:hover{
	color: #fff;
}

/* MODELS */

.users{
	width:calc(100% - 5px);
}
.users:after{
	clear:both;
	content:" ";
	display:block;
}
.users>div{
	width:calc(50% - 5px);
	margin:5px 0 0 5px;
	float:left;
	position:relative;
}
.users>div>div{
	width:100%;
	padding-bottom: 56.25%;
}
.users>div>div>div{
	position:relative;
}
.users>div img{
	width:100%;
	display:block;
	background-color:#282828;
}
.users>div a{
	top:0;
	left:0;
	right:0;
}
.users>div .username,.users>div a{
	text-decoration:none;
	position:absolute;
	bottom:0;
}
.users>div .username{
    color: #fff;
    padding: 0;
    font-size: 10px;
	width: calc(100% - 10px);
    background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	padding: 5px;
	font-family: 'Roboto', sans-serif;
}
.users>div .username .nick{
	flex-basis: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}
.users>div .username .age{
	flex-basis: 20%;
	text-align: right;
}

/* FOOTER */

footer{
	background-color: #180000;
	line-height: 1.5;
}
.footer_centered{
	width: calc(95% - 10px);
    max-width: 1500px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	padding: 10px 5px;
	box-sizing: border-box;
}
.footer_left{
	display: flex;
	flex-direction: column;
	flex-basis: 45%;
	text-align: left;
}
.footer_left_top{
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 10px;
}
.footer_left_bottom h6{
	font-size: 11px;
	color: #a5a5a5;
	text-align: justify;
}
.footer_logo{
	height: 40px;
	margin-right: 10px;
}
.mobile_version{
	display: flex;
	align-items: baseline;
	color: #ececec;
	font-size: 12px;
	transition: .1s ease;
}
.fa-mobile-alt{
	margin-left: 5px;
	font-size: 11px;
}
.footer_right{
	display: flex;
	flex: 1 1;
    justify-content: flex-end;
}
.footer_right div{
	display: flex;
	flex-direction: column;
	margin-left: 25px;
	margin-top: 25px;
}
.footer_right h3{
	color: #ececec;
	font-size: 11px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer_right svg{
	font-size: 12px;
	margin-left: 5px;
}
.footer_right a{
	display: block;
	color: #a5a5a5;
	font-size: 11px;
	padding: 3px;
}
.footer_right a:hover{
	color: #ececec;
}

/* Registration */
.modal {
	position: fixed;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.75);
	top:0;
	bottom:0;
	left:0;
	right:0;
}
.model_register {
	width:90%;
	max-width: 800px;
	
	background-color:#333;
	position: absolute;
	left:0;
	right:0;
	
	height:200px;
	margin:calc(50vh - 100px) auto;
	z-index:1000;


}
.registerForm{
	margin: 0 20px;
}
.model_register input{
font-size: 15px;
height: 22px;
display: inline-block;
width: calc(65% - 20px);
padding: 9px 10px;
border: 0;
outline: 0;
float: left;
color: rgb(75 75 75);
background: white;
}
.model_register button{
transition: background-color .25s ease;
    text-transform: uppercase;
    height: 16px;
    background-color: #650900;
    display: inline-block;
    padding: 12px 0;
    color: #fff;
    cursor: pointer;
    right: 20px;
    bottom: 20px;
    border: 0;
    width: 35%;
	margin-left: 0;
	font-size: 13px;
    float: right;
    border-radius: 0;
    margin-top: 0;
	letter-spacing: 3px;
}
.model_register .close{
	float: right;
    padding: 3px 10px 0 0;
	font-size: 18px;
	cursor:pointer;
}
.model_register h1{
	margin: 20px 0 20px 0;
    font-size: 20px;
}
.model_register h2{
	margin-top: 90px;
    font-size: 14px;
    padding: 0 20px;
}



/* 404 */

.not_found{
	text-align: center;
	min-height: 100vh;
}
.not_found h1{
	color: #a5a5a5;
	font-size: 16px;
	padding: 30px;
}

/* CHATROOM */

.close_chat_icon{
    display: flex;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    padding: 10px;
	background-color: rgba(0, 0, 0, 0.2);
	transition: .1s ease;
}
.close_chat_icon:hover{
	background-color: rgba(0, 0, 0, 0.25);
}
.close_chat_icon img{
	height: 20px;
    width: 20px;
}
iframe{
	border: none;
	padding: 0;
	margin: 0;
	background-color: #000;
}
.chatroom{
	background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
}
.chatroom-contentwrap{
	padding-top: 56.25vw;
	color: #ececec;
}
.chatroom-current-model{
	background-color: #1b1b1b;
	height: 20px;
	padding: 10px;
	text-align: left;
	font-size: 14px;
	text-transform: uppercase;
	color: #cecece;
	display: flex;
	align-items: flex-end;
}
.chatroom-current-model img{
	height: 20px;
	padding-right: 10px;
}
.chatroom-topnav{
    display: flex;
	background-color: #272727;
	padding: 10px 5px;
	align-items: center;
}
.chatroom-topnav-nowlivebox{
    border: 1px solid #005a01;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    color: #cecece;
    background: rgb(41, 41, 41);
    background: linear-gradient(0deg, rgb(27, 27, 27) 0%, rgb(41, 41, 41) 100%);
    border-radius: 3px;
}
.chatroom-topnav-btns{
	display: flex;
    flex: 1 1;
}
.chatroom-topnav-btn{
	padding: 10px 5px;
	flex: 1 1;
	margin-left: 5px;
	font-size: 10px;
	background: rgb(41, 41, 41);
    background: linear-gradient(0deg, rgb(27, 27, 27) 0%, rgb(41, 41, 41) 100%);
	color: #cecece;
	border: 1px solid transparent;
	display: flex;
    justify-content: center;
	align-items: center;
	border-radius: 3px;
}
.free_chat{
	border: 1px solid rgba(191, 17, 0, 0.5);
	flex: 3 1;
}
.go_private{
	border: none;
	flex: 3 1;
	background: rgb(176,16,0);
    background: linear-gradient(0deg, rgb(144, 13, 0) 0%, rgba(191,17,0,1) 100%);
}
.join{
	border: 1px solid #424242;
}
.chatroom-info{
	padding: 10px;
	font-size: 12px;
	background-color: #333;
}
.chatroom-info div{
	display: flex;
	margin-bottom: 10px;
	color: #1f1f1f;
}
.chatroom-info span{
	padding-right: 10px;
	color: #ececec;
	font-size: 11px;
}
.chatroom-info p{
	margin: 0;
	text-transform: capitalize;
	text-align: justify;
	font-size: 11px;
	color: #9a9a9a;
}
.recent_shot{
	display: flex;
}
.chatroom-contentwrap h4{
	padding: 10px;
	padding-top: 15px;
	color: #9a9a9a;
	text-align: left;
}
.chatroom-profile-picture{
	width: 150px;
}
.highlight{
	color: #cecece;
}

/* DESKTOP HEADER */

header{
	background-color: #180000;
}
.header_centered{
	width: 95%;
	max-width: 1500px;
	margin: 0 auto;
}
nav{
	background-color: #1b1b1b;
}
.nav_centered{
	width: 95%;
	max-width: 1500px;
	margin: 0 auto;
	box-sizing: border-box;
}
.header__pc_top{
	display: flex;
	justify-content: space-between;
	padding: 10px 5px;
}
.header__pc_content_left{
	display: flex;
	align-items: center;
}
.header__pc_logo a{
	display: flex;
}
.header__pc_heading_wrap{
	display: flex;
    flex-direction: column;
	justify-content: space-between;
	margin-left: 10px;
}
.header__pc_headingtxt{
	font-size: 11px;
	font-style: italic;
	margin-left: 10px;
}
.header__pc_buttons{
	display: flex;
	justify-content: flex-end;
}
.header__pc_button_account{
	background: rgb(176,16,0);
    background: linear-gradient(0deg, rgba(176,16,0,1) 0%, rgba(191,17,0,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
	color: #ececec;
    height: 25px;
	width: 170px;
	border-radius: 3px;
}
.header__pc_button_account:hover{
	background: linear-gradient(0deg, rgba(176,16,0,1) 0%, rgba(176,16,0,1) 100%);
	border:none;
}
.header__pc_button_login{
	display: flex;
    align-items: center;
    justify-content: center;
    color: #ececec;
    height: 25px;
    width: 80px;
    margin-right: 5px;
	background: rgb(41, 41, 41);
    background: linear-gradient(0deg, rgb(27, 27, 27) 0%, rgb(41, 41, 41) 100%);
	border-radius: 3px;
}
.header__pc_button_login:hover{
	background: linear-gradient(0deg, rgb(27, 27, 27) 0%, rgb(27, 27, 27) 100%);
	color: #ececec;
	border:none;
}
.navlinks{
	display: flex;
	margin: 0 5px;
}
.navlink{
	padding: 10px 0;
	margin-right: 30px;
    color: #cecece;
	transition: .1s ease;
	border-bottom: 1px solid transparent;
}
.navlink:hover, .navlink_active{
	color: #ececec;
	border-bottom: 1px solid #bf1100;
}

/* SIDEMENU DESKTOP */

#sidemenu__desktop{
	display: none;
	width: 220px;
	background-color: #1b1b1b;
	margin: 0 5px;
}
.sidemenu{
	height: 100%;
	color: #000;
	font-size: 20px;
}
.credits_box{
	margin-bottom: 10px;
}
.promotions{
	display: block;
	padding: 12px;
    background: rgb(176,16,0);
    background: linear-gradient(0deg, rgb(144, 13, 0) 0%, rgba(191,17,0,1) 100%);
	color: #ececec;
	font-size: 12px;
	margin: 5px;
	transition: .1s ease;
	border-radius: 3px;
	width: calc(100% - 33px);
}
.promotions:hover{
	background: linear-gradient(0deg, rgb(144, 13, 0) 0%, rgb(144, 13, 0) 100%);
}
.fa-coins{
	font-size: 14px;
	margin-right: 5px;
}
.sidemenu_top_options{
	display: flex;
	flex-direction: column;
}
.sidemenu_top_options a{
	display: flex;
	color: #a5a5a5;
	font-size: 11px;
	padding: 10px;
	text-align: left;
	transition: .1s ease;
}
.sidemenu_top_options a:hover{
	color: #ececec;
}
.sidemenu_top_options svg{
	font-size: 11px;
	margin-right: 5px;
}
#side_list{
	color: #ececec;
	font-size: 12px;
	text-align: left;
}
.side_list_title{
	padding: 10px;
}
#side_list li a{
	padding: 10px;
	display: block;
	color: #a5a5a5;
	font-size: 11px;
	transition: .1s ease;
}
#side_list li a:hover{
	color: #ececec;
}
.active_sidepage{
	color: #ececec !important;
}

.loading{
	margin: auto;
	width: 50%;
	border: 3px solid green;
	padding: 10px;
}



/* user container heading */
.users_heading{
	text-align: left;
	padding: 10px 5px;
	font-size: 14px;
	text-transform: capitalize;
}

/* TEXT CONTENT */

#content{
	margin-bottom: 70px;
	line-height: 1.5;
}
#content h1{
    padding: 10px 0;
    font-size: 16px;
    text-align: left;
    color: #ececec;
}
#content p{
	padding: 5px 0;
	font-size: 12px;
	text-align: justify;
	color: #a5a5a5;
}
.text_content{
	box-sizing: border-box;
    padding: 10px;
}



/* media queries */

@media only screen and (min-width: 568px) {
	.users>div {
		width: calc(33.3333% - 5px);
	}
}

@media only screen and (min-width: 768px) {
	#menu{
		width: 300px;
	}
	.close_chat_icon{
		top: 10px;
		left: 10px;
	}
	.chatroom-topnav-nowlivebox{
		padding: 10px 30px;
	}
}

/* Desktop */

@media only screen and (min-width: 769px) {
	.users{
		min-height: 100vh;
	}
	.users>div {
		width: calc(50% - 5px);
	}
	.wrap_sidemenu_also{
		display: flex;
		width: 95%;
		margin: 0 auto;
		max-width: 1500px;
		padding: 10px 0;
	}
	#sidemenu__desktop{
		display: block;
	}
	.container {
		background-color: #333;
	}
	.logo_pc{
		height: 60px;
	}
	.search_wrap{
		padding: 0;
		margin-top: 5px;
	}
	.search_input{
		padding: 1px 0 0 10px;
		height: 29px;
		font-size: 12px;
		width: 100%;
		color: #151515;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	#search_button{
		background-color: #eaeaea;
		cursor: pointer;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	::-webkit-input-placeholder{
		color: #151515;
		opacity: 1;
		font-size: 12px;
	}
	::placeholder{
		color: #151515;
		opacity: 1;
		font-size: 12px;
	}
	:-ms-input-placeholder{
		color: #151515;
		font-size: 12px;
	}
	::-ms-input-placeholder{
		color: #151515;
		font-size: 12px;
	}
	.navfilters_flex{
		margin-top: 10px;
	}
	.open_filters {
		padding: 0;
		cursor: pointer;
		background-color: #be1100;
	}
	.open_filters h1{
		font-size: 12px;
		padding: 10px;
		color: #ececec;
		transition: .1s ease;
		text-align: left;
		border-radius: 3px;
	}
	.header_icon {
		display: none;
	}
	.filters_devices{
		position: relative;
		padding: 0;
		background-color: transparent;
	}
	.filters_title_close{
		margin: 0;
		padding: 7px 10px;
		display: flex;
		justify-content: flex-end;
		background-color: #be1100;
	}
	.filters_title_close h2{
		display: none;
	}
	.close_icon {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.sorters{
		flex-direction: column;
		align-items: unset;
	}
	.sorter{
		cursor: pointer;
		flex: 1 1;
		height: unset;
		font-size: 11px;
		padding: 10px;
		margin: 0;
		background-color: #1b1b1b;
		border: none;
		color: #a5a5a5;
		text-align: left;
		transition: .1s ease;
	}
	.sorter:hover{
		color: #ececec;
	}
	#search_suggestions li a{
		padding: 10px;
		background-color: #fff;
		color: #151515;
		border-top: 1px solid #eaeaea;
	}
	#search_suggestions li a:hover{
		background-color: #eaeaea;
	}
	.users>div .username{
		font-size: 11px;
	}
	#liveroom_container{
		width: 95%;
		margin: 0 auto;
		max-width: 1500px;
		padding: 10px 5px;
		box-sizing: border-box;
	}
	.users_wrapper{
		width: 100%;
	}
	.chatroom{
		position: relative;
		display: flex;
	}
	.chatroom-contentwrap{
		padding-top: 0;
	}
	.chatroom-contentwrap h4{
		display: none;
	}
	
	.contact{
		width: 100%;
	}
	.not_found{
		width: 100%;
		text-align: center;
	}
	.not_found h1{
		padding: 10px;
	}
	.not_found p{
		padding: 0 30px 10px 0;
		font-size:13px;
	}
	.not_found a{
		color:#fff;
	}

	.center_chatmodels {
		width: 95%;
		max-width: 1500px;
		margin: 0 auto;
	}
	iframe{
		background-color: #1b1b1b;
	}
	.chatroom-info{
		background-color: #2b2b2b;
	}
	#content{
		margin-bottom: 10px;
	}
	.text_content{
		width: calc(95% - 10px);
		max-width: 1500px;
		margin: 0 auto;
		padding: 10px 5px;
	}

	.modal {
		background-color: rgba(0, 0, 0, 0.55);	
	}
	.model_register {
		margin:calc(50vh - 150px) auto;
		height:300px;
	}
	
	.model_register input{
		height: 40px;
	}
	.model_register button{
		font-size:15px;
		height: 34px;
	}
	
	.model_register h1{
		margin: 40px 0 40px 0;
	}
	.model_register h2{
		margin-top: 150px;
		font-size: 16px;
	}
	

}

@media only screen and (min-width: 1024px) {
	.users>div {
		width: calc(33.3333% - 5px);
	}
	.search_input{
		width: 250px;
	}
}

@media only screen and (min-width: 1280px) {
	.users>div {
		width: calc(25% - 5px);
	}
}

@media only screen and (min-width: 1520px) {
	.users>div {
		width: calc(20% - 5px);
	}
}

@media only screen and (min-width: 1920px) {
	.users>div {
		width: calc(16.6666666667% - 5px);
	}
}

@media only screen and (min-width: 1921px) {
	.header_centered{
		width: 90%;
		max-width: unset;
	}
	.nav_centered{
		width: 90%;
		max-width: unset;
	}
	.wrap_sidemenu_also{
		width: 90%;
		max-width: unset;
	}
	#liveroom_container{
		width: 90%;
		max-width: unset;
	}
	.footer_centered{
		width: 90%;
		max-width: unset;
	}
	.center_chatmodels{
		width: 90%;
		max-width: unset;
	}
	.text_content{
		width: 90%;
		max-width: unset;
	}
}

@media only screen and (min-width: 2200px) {
	.users>div {
		width: calc(14.2857142857% - 5px);
	}
}

@media only screen and (min-width: 2560px) {
	.users>div {
		width: calc(12.5% - 5px);
	}
}

@media only screen and (min-width: 3000px) {
	.users>div {
		width: calc(11.1111111111% - 5px);
	}
}

@media only screen and (min-width: 3300px) {
	.users>div {
		width: calc(10% - 5px);
	}
}

@media only screen and (min-width: 3600px) {
	.users>div {
		width: calc(9.09090909091% - 5px);
	}
}
